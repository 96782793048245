@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stepItem {
  display: flex;
  position: relative;
  align-items: start;
  width: 100%;
  margin-bottom: 20px;

  @media (--viewportSmall) {
    margin-bottom: 40px;
    align-items: center;
  }
}

.circle {
  background-color: rgba(255, 174, 171, 1);
  border-radius: 50%;
  color: #fff;
  text-align: center;
  z-index: 1;
  font-family: var(--fontFamily);
  font-weight: 700;
  font-size: 14px;
  /* line-height: 19px; */
  width: 32px;
  min-width: 32px;
  height: 32px;
  line-height: 2.2;

  @media (--viewportSmall) {
    width: 52px;
    min-width: 52px;
    height: 52px;
    font-size: 20px;
    line-height: 2.5;
  }
}

.dynamicLine {
  position: absolute;
  width: 2px;
  background-color: rgba(255, 174, 171, 1);
  z-index: 0;
  left: 15px;
  height: calc(100% + 32px + 20px);

  @media (--viewportSmall) {
    height: calc(100% + 40px);
    left: 25px;
  }
}

.stepItem:last-child .dynamicLine {
  display: none;

  @media (--viewportSmall) {
    display: block;
    transform: translateY(-50%);
  }
}
.stepItem:first-child .dynamicLine {
  transform: none;

  @media (--viewportSmall) {
    transform: translateY(50%);
  }
}

.stepInfo {
  border-radius: 14px;
  background-color: rgba(204, 235, 229, 0.2);
  width: 100%;
  padding: 10px;
  margin-left: 20px;

  @media (--viewportSmall) {
    padding: 30px;
    margin-left: 40px;
  }
}

.stepInfoTitle {
  font-family: var(--fontFamily);
  margin: 0;
  text-align: left;
  padding: 0;
  font-weight: 700;
  line-height: 22px;
  font-size: 18px;

  @media (--viewportSmall) {
    line-height: 34px;
    font-size: 24px;
  }
}

.stepInfoList {
  list-style-type: disc;
  padding-left: 14px;

  @media (--viewportSmall) {
    padding-left: 20px;
  }
}

.stepInfoListItem {
  font-weight: 400;
  font-family: var(--fontFamily);
  text-align: left;

  font-size: 15px;
  line-height: 22px;
  margin-top: 10px;

  @media (--viewportSmall) {
    font-size: 20px;
    line-height: 30px;
    margin-top: 20px;
  }
}

.title {
  font-family: var(--fontFamily);
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  margin: 0;
  padding: 0;

  @media (--viewportSmall) {
    font-size: 40px;
  }
}

.subTitle {
  font-family: var(--fontFamily);
  font-weight: 700;
  text-align: center;
  padding: 0;
  margin: 10px 0 0;
  font-size: 15px;
  line-height: 22px;

  @media (--viewportSmall) {
    font-size: 24px;
    line-height: 1.2;
    margin: 20px 0 0;
  }
}

.headline {
  max-width: 100%;
  margin: 0 auto 20px;
  padding: 0;

  @media (--viewportSmall) {
    margin: 0 auto 50px;
    padding: 0 36px;
  }
}
