@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: 8px;
  height: 150px;

  /* @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  } */
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: 8px;
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 20px 0 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: 700;
  color: #323cb7;
  font-size: 18px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: 700;
  font-size: 16px;
  color: #1b1b1b;
  margin: 0;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 10px;
  padding-bottom: 10px;

  display: flex;
  align-items: center;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  & span {
    font-family: var(--fontFamily);
    font-weight: 400;
    font-size: 14px;
    color: #1b1b1b;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.basketIconWrapper {
  border-radius: 50%;
  border: 1px solid #000;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.priceSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin-right: 6px;
}

.authorName {
  font-weight: 400;
  font-size: 14px;
  color: #1b1b1b;
}

.listingCategory {
  font-weight: 700;
  font-size: 14px;
  color: #1b1b1b;

  & span > span {
    font-weight: 400;
    font-size: 14px;
    color: #1b1b1b;
  }
}

.products {
  background-color: #ffcb47;
  border: none;
}
.trainings {
  background-color: #ffaeab;
  border: none;
}
.consultations {
  background-color: #ccebe5;
  border: none;
}
