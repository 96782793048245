@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  align-items: center;
  max-width: 1040px;
  margin: 0;
  flex-direction: column;
  padding: 60px 10px;

  @media (--viewportMedium) {
    margin: 0 auto;
    padding: 128px 10px;
    flex-direction: row;
  }
}

.title {
  font-family: var(--fontFamily);
  font-weight: 700;
  margin: 0 0 10px;
  padding: 0;
  text-align: center;
  line-height: 32px;
  font-size: 24px;
  margin-top: 10px;

  @media (--viewportMedium) {
    margin-top: 0;
    line-height: 1.2;
    font-size: 40px;
    text-align: left;
  }
}

.description {
  font-family: var(--fontFamily);
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: left;

  font-size: 16px;
  line-height: 24px;

  @media (--viewportMedium) {
    text-align: left;
    font-size: 20px;
    line-height: 30px;
  }
}

.iconWrapper {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 4;

  @media (--viewportMedium) {
    width: 100px;
    height: 100px;
    line-height: 6;
  }
}

.rightSection {
  margin-left: 0;

  @media (--viewportMedium) {
    margin-left: 50px;
  }
}
