@import '../../../styles/customMediaQueries.css';

.root {
  border-radius: 2px;
}

.input {
  border-radius: 4px;
  margin-bottom: 16px;
  border: 1px solid var(--marketplaceColorDark);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.008em;
  height: 46px;
}

.textarea {
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid var(--marketplaceColorDark);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.008em;
  height: 46px;
}

.button {
  min-height: 46px;
  background: var(--marketplaceColorLight);
  margin-top: 24px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.successSendMessage {
  color: var(--colorSuccess);
}
