@import '../../../styles/customMediaQueries.css';


.root {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  /*background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)), url('../../../assets/background-1440.jpg');*/
  /*background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));*/
  /*background-color: var(--matterColor);*/
  background-size: cover;
  background-position: center center;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (--viewportLarge) {

  }
}

.heroContent {
  margin: 0 24px 0 24px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50px;

  @media (--viewportLarge) {
    margin: 0 7.5vw 0 7.5vw;
    padding-bottom: 0;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;

  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1257px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--marketplaceColorLight);
  font-weight: 700;
  font-size: 50px;
  line-height: 52px;
  text-align: center;

  @media (--viewportLarge) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    font-size: 76px;
    line-height: 76px;
    margin: 40px 0 12px;
    text-align: left;
  }
}

.darkColor {
  color: var(--marketplaceColorDark);
}


.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--colorBlack);
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  font-size: 23px;
  line-height: 32px;

  @media (--viewportLarge) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 10px 0;
    font-size: 36px;
    line-height: 45px;
    text-align: left;
  }
}

.heroDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorBlack);
  max-width: 80%;
  margin-bottom: 30px;
  text-align: center;

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 30px;
    text-align: left;
  }
}

.heroButton {
  composes: buttonDefault from global;
  width: 90%;
  height: 46px;
  background: var(--colorYellow);
  color: var(--colorLightBlack);
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 46px;

  @media (--viewportLarge) {
    font-size: 20px;
    width: 168px;
  }

}

.titlesHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 100%;
  max-width: 100%;
  order: 2;

  @media (--viewportLarge) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 0 0 50%;
    max-width: 50%;
    order: 1;
  }
}

.imageHolder {
  flex: 0 0 100%;
  max-width: 100%;
  order: 1;
  padding: 50px 0;

  @media (--viewportLarge) {
    flex: 0 0 50%;
    max-width: 50%;
    order: 2;
    padding: 100px 0;
  }
}

.image {
  width: 100%;
  max-width: 100%;
}

.heroSubTitle {

}

