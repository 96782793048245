@import '../../styles/customMediaQueries.css';

.root {
  background-color: #fcf9f4;
  position: relative;

  padding: 40px 0;
  margin-top: 40px;

  @media (--viewportSmall) {
    padding: 80px 0 50px;
    margin-top: 100px;
  }
}

.heading {
  font-weight: 700;
  font-family: var(--fontFamily);
  color: #1b1b1b;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 24px;
  line-height: 2;

  @media (--viewportSmall) {
    font-size: 40px;
    line-height: 1.2;
  }
}

.description {
  font-family: var(--fontFamily);
  color: #000000;
  margin: 0 auto;
  padding: 0;
  max-width: 620px;
  font-weight: 400;
  text-align: center;
  font-size: 15px;
  line-height: 2;

  @media (--viewportSmall) {
    font-size: 24px;
    line-height: 1.2;
  }
}

.signupLink {
  font-weight: 600;
  font-family: var(--fontFamily);
  text-align: center;
  margin: 0;
  background-color: #9191d6;
  border-radius: 20px;
  padding: 8px 32px;
  display: inline-block;
  height: 46px;
  background-color: transparent;
  border: 1.5px solid #9191d6;
  color: #9191d6;
  width: 100%;
  font-size: 20px;
  line-height: 1.6;

  @media (--viewportSmall) {
    line-height: 1.2;
    width: 238px;
  }

  &:hover {
    background-color: #9191d6;
    text-decoration: none;
    color: #fdfdfd;
  }
}

.btnWrapper {
  text-align: center;
  margin-top: 10px;

  @media (--viewportSmall) {
    margin-top: 30px;
  }
}

.descriptionWrapper {
  margin-top: 10px;

  @media (--viewportSmall) {
    margin-top: 30px;
  }
}

.iconWrapper {
  background-color: #9191d6;
  border-radius: 50%;
  position: absolute;
  text-align: center;
  width: 50px;
  height: 50px;
  top: -10%;
  left: calc(50% - 25px);
  line-height: 1;

  @media (--viewportSmall) {
    line-height: 6;
    top: -15%;
    width: 100px;
    height: 100px;
    left: calc(50% - 50px);
  }
}
