@import '../../styles/customMediaQueries.css';

.root {
  max-width: 1280px;
  margin: 40px auto 0;

  @media (--viewportMedium) {
    margin: 0 auto;
  }
}

.content {
  display: flex;
  flex-direction: column;

  @media (min-width: 868px) {
    flex-direction: row;
  }
}

.mainTitle {
  font-family: var(--fontFamily);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 48px;
  }
}

.title {
  font-family: var(--fontFamily);
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;
  padding: 0;
  margin-top: 0;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 30px;
    text-align: left;
    margin-top: 30px;
  }
}

.description {
  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.list {
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 20px;
}

.listItem {
  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 20px;
}

.image {
  max-width: 320px;

  @media (--viewportSmall) {
    max-width: 555px;
  }
}

.leftSide {
  margin-right: 0;
  text-align: center;

  @media (min-width: 868px) {
    margin-right: 20px;
    text-align: left;
  }
}

.section {
  padding: 30px 24px;
  margin-top: 20px;

  @media (--viewportMedium) {
    padding: 0;
    background-color: transparent !important;
    margin-top: 0;
  }
}

.section1 {
  background-color: rgba(255, 250, 237, 1);
}

.section2 {
  background-color: rgba(255, 247, 247, 1);
}

.section3 {
  background-color: rgba(244, 244, 251, 1);
}
