@import '../../styles/customMediaQueries.css';

.section {
  margin: 0 24px 50px 24px;

  @media (--viewportLarge) {
    width: min(calc(var(--contentMaxWidth) + 72px), 100vw);
    margin: 0 auto 117px auto;
  }
}

.accordionSection {
  background: var(--colorFailLight);
  padding-top: 50px;

  @media (--viewportLarge) {
    padding-top: 100px;
  }
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--colorLightBlack);
  padding: 0;
  margin: 0;

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 30px;
  }
}

.tile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.left {
  flex: 0 0 calc(100% - 50px);
  max-width: calc(100% - 50px);
}

.iconHolder {
  height: 40px;
  width: 40px;
  flex: 0 0 40px;
  border-radius: 6px;
  background: var(--marketplaceColorLight);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}

.inner {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

span {

}

.selected {

}

.heading {
  width: 100%;
  background: var(--colorWhite);
  border-radius: 20px;
  margin-bottom: 20px;
  padding: 25px;
}
