@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  background-color: #fcf9f4;
  border-top: 1px solid rgba(178, 178, 178, 0.3);
}

.logoLink {
  display: inline-block;
  padding: 0;

  & > div {
    margin: 0;
    align-items: start;
  }

  &:hover {
    text-decoration: none;
  }
}

.logoWrapper {
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.copyrightWrapper {
  text-align: center;
  padding: 16px 32px;
  background-color: #f8f5f1;
  border-top: 1px solid rgba(178, 178, 178, 0.3);
  width: 100%;

  @media (min-width: 1280px) {
    padding: 16px 0;
  }
}

.copyright {
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #1b1b1b;
  max-width: 1280px;
  margin: 0 auto;
}

.footerContent {
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  padding: 25px 24px;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  @media (min-width: 1280px) {
    padding: 25px 0;
  }
}

.socialsList {
  display: flex;
  max-width: 100px;
  justify-content: space-between;
  margin: 10px auto;

  & li {
    padding: 0;
  }

  @media (--viewportMedium) {
    min-width: 100px;
    margin: 0;
  }
}

.linkItem {
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  padding: 0;

  & a {
    color: #1b1b1b;
  }

  @media (--viewportMedium) {
    width: 50%;
  }
}

.linkItem:nth-child(1) {
  order: 1;
}
.linkItem:nth-child(2) {
  order: 3;
}
.linkItem:nth-child(3) {
  order: 5;
}
.linkItem:nth-child(4) {
  order: 2;
}
.linkItem:nth-child(5) {
  order: 4;
}

.linksList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;

  @media {
    text-align: left;
  }
}

.linksWrapper {
  width: 100%;
  margin: 0;

  @media (--viewportMedium) {
    width: 50%;
    margin-left: auto;
  }
}
