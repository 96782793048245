@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev,
.next {
  display: block;

  /* Dimensions */
  padding-top: 26px;
  padding-bottom: 26px;

  /* Reset <a> tag sizing */
  line-height: 1.2;
  /*  font-size: 0; */

  color: #9191d6;

  &:hover {
    text-decoration: none;
  }

  & span {
    display: inline;
    font-family: var(--fontFamily);
    font-weight: 700;
    line-height: 1;
    font-size: 12px;

    @media (--viewportSmall) {
      font-size: 16px;
    }
  }
}

.prev {
  /* Minimum touch size 44px (svg width + padding) */
  padding-right: 5px;

  @media (--viewportSmall) {
    padding-right: 33px;
  }

  & span {
    margin-left: 10px;
  }
}
.next {
  /* Minimum touch size 44px (svg width + padding) */
  padding-left: 5px;

  @media (--viewportSmall) {
    padding-left: 33px;
  }

  & span {
    margin-right: 10px;
  }
}

.arrowIcon {
  /* Color for svg icons */
  fill: #9191d6;
  stroke: #9191d6;

  &:hover {
    fill: #9191d6;
    stroke: #9191d6;
  }
}

.disabled,
.disabled:hover {
  fill: var(--colorGrey300);
  stroke: var(--colorGrey300);
}

.pageNumberList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */
.pageNumberList1Items {
  flex-basis: 30px;
}

.pageNumberList2Items {
  flex-basis: 60px;
}

.pageNumberList3Items {
  flex-basis: 100px;
}

.pageNumberList4Items {
  flex-basis: 120px;

  @media (--viewportSmall) {
    flex-basis: 160px;
  }
}

.pageNumberList5Items {
  flex-basis: 150px;

  @media (--viewportSmall) {
    flex-basis: 200px;
  }
}

.pageNumberList6Items {
  flex-basis: 170px;

  @media (--viewportSmall) {
    flex-basis: 250px;
  }
}

.pageNumberList7Items {
  flex-basis: 178px;

  @media (--viewportSmall) {
    flex-basis: 275px;
  }
}

.toPageLink {
  padding: 8px;
  color: #9191d6;
  font-weight: 700;
  font-size: 12px;
  line-height: 2;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportSmall) {
    padding: 21px 5px;
    font-size: 16px;
    line-height: 1.5;
  }
}

.currentPage {
  /* Dimensions */
  padding: 8px;
  /* border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: var(--colorBlack); */

  /* Color */
  /* color: var(--colorBlack); */

  width: 24px;
  height: 24px;
  text-align: center;

  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 8px;

  border-radius: 8px;
  background-color: #9191d6;

  @media (--viewportSmall) {
    padding: 21px 5px;
    font-size: 16px;
    line-height: 0;
    width: 40px;
    height: 40px;
  }
}

.paginationGap {
  /* Dimensions */
  padding: 8px;

  /* Color for svg icons */
  /* color: var(--colorGrey700); */

  /* Ellipsis is raised from baseline */
  /* line-height: 13px; */

  color: #9191d6;
  font-weight: 700;
  font-size: 12px;
  line-height: 2;

  @media (--viewportSmall) {
    padding: 21px 5px;
    font-size: 16px;
    line-height: 1;
  }
}
