@import '../../styles/customMediaQueries.css';

/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--colorWhite);
}

.content {
  max-width: 590px;
  width: 80%;
  margin-top: 60px;
  margin-bottom: 60px;
  background: var(--marketplaceColorLighter);
  padding: 0 20px 25px;

  @media (--viewportMedium) {
    margin-top: 40px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  text-align: center;
  margin-top: 16px;
  font-weight: 700;
  letter-spacing: 0.008em;

  @media (--viewportMedium) {
    font-size: 36px;
    line-height: 49px;
    margin-top: 22px;
  }
}

.description {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.008em;
  max-width: 90%;
  margin: 10px auto;

  @media (--viewportMedium) {
    margin: 10px auto 20px;
  }
}

.form {

}
