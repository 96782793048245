@import '../../styles/customMediaQueries.css';

.root {
  margin: 10px 0 40px;

  @media (--viewportMedium) {
    margin: 80px 0 50px;
  }
}

.description {
  font-family: var(--fontFamily);
  text-align: center;
  max-width: 950px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  margin: 0 auto;

  @media (--viewportSmall) {
    font-size: 20px;
  }
}
