.root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(254, 244, 250, 1);
  padding: 30px;
  border-radius: 14px;
  max-width: 340px;
}

.button {
  background-color: rgba(145, 145, 214, 1);
  cursor: pointer;
  height: 32px;
  width: 32px;
  min-width: 32px;
  border: none;
  outline: none;
  border-radius: 6px;
  line-height: 1;
}

.slider-button:disabled {
  background-color: #b3d9ff;
  cursor: not-allowed;
}

.fullname {
  font-family: var(--fontFamily);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  margin-top: 10px;
}

.description {
  font-family: var(--fontFamily);
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin-top: 20px;
}
