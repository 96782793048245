@import '../../styles/customMediaQueries.css';

.title {
  margin: 0 0 40px;
  padding: 0;
  font-family: var(--fontFamily);
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
}

.iconWrapper {
  width: 64px;
  height: 44px;
  margin: 0 auto;
}

.feedbacksWrapper {
  margin-top: 40px;
}

.list {
  display: flex;
  justify-content: space-between;
}

.listItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(254, 244, 250, 1);
  padding: 30px;
  border-radius: 14px;
  width: 32%;
}

.fullname {
  font-family: var(--fontFamily);
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  margin-top: 10px;
}

.description {
  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
}

.btn {
  border: 1px solid rgba(145, 145, 214, 1);
  background-color: transparent;
  width: 240px;
  height: 46px;
  padding: 8px 32px;
  font-family: var(--fontFamily);
  border-radius: 20px;
  margin: 0 auto;
  font-size: 20px;
  display: block;
  color: rgba(145, 145, 214, 1);
  line-height: 28px;

  &:hover {
    background-color: rgba(145, 145, 214, 1);
    color: rgba(253, 253, 253, 1);
    text-decoration: none;
  }
}

.btnWrapper {
  margin-top: 40px;
}
