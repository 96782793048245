@import '../../styles/customMediaQueries.css';

.root {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 40px;

  @media (min-width: 980px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.block {
  padding: 10px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: rgba(204, 235, 229, 0.2);

  @media (--viewportMedium) {
    padding: 30px;
  }
}

.iconWrapper {
  width: 32px;
  height: 32px;
  background-color: rgba(204, 235, 229, 1);
  border-radius: 50%;
  margin: 0 auto 10px;
  line-height: 30px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    line-height: 60px;
    width: 64px;
    height: 64px;
  }
}

.title {
  font-family: var(--fontFamily);
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
  padding: 0;
  flex-grow: 1;

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 30px;
    flex-grow: 1;
  }
}

.description {
  font-family: var(--fontFamily);
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  flex-grow: 1;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
    flex-grow: 1;
  }
}

.link {
  width: 100%;
  height: 29px;
  padding: 8px 32px;
  border-radius: 30px;
  background-color: transparent;
  font-family: var(--fontFamily);
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  color: rgba(27, 27, 27, 1);
  flex-shrink: 0;
  outline: 1.5px solid rgba(204, 235, 229, 1);

  @media (--viewportMedium) {
    flex-shrink: 0;
    width: 250px;
    height: 46px;
    font-size: 20px;
    line-height: 30px;
  }

  &:hover {
    background-color: rgba(204, 235, 229, 1);
    text-decoration: none;
  }
}

.titleCategories {
  font-family: var(--fontFamily);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  margin: 0 0 10px;
  padding: 0;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 48px;
  }
}

.descriptionCategories {
  font-family: var(--fontFamily);
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  padding: 0;
  max-width: 750px;
  margin: 0 auto;

  @media (--viewportMedium) {
    font-size: 24px;
    line-height: 30px;
    max-width: 750px;
  }
}

.headline {
  margin-bottom: 30px;

  @media (--viewportMedium) {
    margin-bottom: 50px;
  }
}
