@import '../../styles/customMediaQueries.css';

.root {
  padding: 20px 0 0 24px;

  @media (--viewportMedium) {
    padding: 30px 0 20px 20px;
  }
}

.breadcrumbs {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.breadcrumbItem {
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  & a {
    color: #b2b2b2;

    &:hover {
      text-decoration: none;
    }
  }
}

.active {
  & a {
    color: #000000 !important;
  }
}

.separator {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: #b2b2b2;
  padding: 0 2px;
}
